import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CodeOfPracticePage = () => {
  return (
    <Layout>
      <SEO title="Code of Practice" />

      <div className="flex flex-wrap mt-2 bg-white border border-gray-300">
        <div className="w-full p-4 md:p-10">
          <h1 className={`pb-4 border-b border-gray-300 mb-8`}>
            Code of Practice
          </h1>

          <p>
            Mitchells &amp; Butlers are a signatory to the{" "}
            <a href="/pdfs/Code-of-Practice-Leased-14Mar22.pdf" target="_blank">
              Pub Sector (England &amp; Wales)
            </a>{" "}
            Leased Code of Practice for pub companies with &lt;500 tied pubs,
            and the{" "}
            <a href="/pdfs/Code-of-Practice-Scotland.pdf" target="_blank">
              Pub Sector – Scotland
            </a>{" "}
            Code of Practice which both came into effect from 21st July 2016 and
            was more recently updated and amended on 1st April 2022.
          </p>

          <p>
            These Codes set the standards you can expect from your interaction
            with us as your Landlord both in the recruitment process prior to
            taking one of our Leases and then subsequently throughout the life
            of your relationship with us.
          </p>

          <p>
            In addition we have a{" "}
            <a
              href="/pdfs/Tenanted-code-of-practice-supplementary-information.pdf"
              target="_blank"
            >
              supplementary
            </a>{" "}
            document to the Code that gives specific details on how we intend to
            build and maintain an open and honest relationship with our Lessees.
          </p>

          <p>
            A further document, the{" "}
            <a
              href="/pdfs/Dilapidations-Best-Practice-Guidance-final-v1-Feb-2022-1-BII-UKH.pdf"
              target="_blank"
            >
              Best practice guide to the management of pub repairs and
              Dilapidations
            </a>
            , was drawn up and issued in February 2022.&nbsp; It has been
            written as guidance with the aim of helping both pub companies and
            their tenants minimise the disputes relating to repairs and
            dilapidations at the end of the term.&nbsp; Whilst not legally
            binding Mitchells &amp; Butlers are signed up to the principles of
            best practice behaviour that are set out in this document.
          </p>

          <h2>Key principles of the Code</h2>

          <p>Companies which subscribe to the Code agree to:</p>

          <ul>
            <li>
              Abide by its terms and to act at all times in the spirit with
              which Code has been compiled;
            </li>
            <li>
              Act with integrity and honesty at all times and conduct business
              in a professional, fair, and legal manner;
            </li>
            <li>
              Be transparent about their terms of business and other dealings,
              particularly any charges made or costs passed on, and the way in
              which rent has been assessed;
            </li>
            <li>
              Offer contracts that are fair, reasonable and comply with all
              legal requirements;
            </li>
            <li>
              Deal with complaints speedily and fairly, in accordance with a
              clearly defined internal dispute mechanism and with access to
              independent dispute resolution, where appropriate, if such a
              mechanism fails to resolve the complaint.
            </li>
          </ul>

          <p>
            The Code also places obligations on Lessees to ensure that they are
            fully equipped to make informed commercial decisions. It includes
            requirements or recommendations for Lessees to take independent
            professional advice.
          </p>

          <p>
            Furthermore the Code also sets out a requirement for prospective
            Lessees to undertake training to ensure that they fully understand
            the implications of a pub lease. This training is known as Pre Entry
            Awareness Training (PEAT) and is available via the British Institute
            of Innkeeping website{" "}
            <a
              rel="noopener"
              href="https://www.bii.org/BII/BII/Industry-Advice/PEAT.aspx?hkey=08bbfbbc-087d-4245-969d-d92c4ed1fefc"
              target="_blank"
            >
              www.bii.org/industry-advice/peat/
            </a>
            .
          </p>

          <h2>External support for Lessees</h2>

          <p>
            As signatories to the code Mitchells and Butlers are committed to
            the professional standards enshrined in the Leased Code of Practice
            and to the operation of both the <em>PIRRS</em> and{" "}
            <em>PICA-Service</em> that are available in support of lessees.
          </p>

          <p>
            The Pub Independent Rent Review Scheme (<em>PIRRS</em>) provides an
            independent rent assessor who will determine what a fair rent for
            your pub is when either the tenancy or lease is up for renewal or at
            any regular rent review. Details of the <em>PIRRS</em> application
            process and procedures to be followed can be found at{" "}
            <a
              href="https://www.pirrscheme.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              www.pirrscheme.com
            </a>.
          </p>

          <p>
            Where you believe that the company has not complied with the terms of the Leased Code of Practice, or where our behaviour has not reflected the intentions set out in the Code, a similar service is available through the Pub Independent Conciliation & Arbitration Service (PICA-Service
            ). In the event that you have been unable to reach a resolution of a problem or complaint with us after going through our Company internal complaint resolution procedures you may refer the problem to PICA-Service. Full details of the application process and the procedures to be followed when referring  complaint to PICA-Service can be found at
            <a
              href="https://www.picaservice.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              www.picaservice.com
            </a>.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default CodeOfPracticePage
